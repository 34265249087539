import { Helmet } from 'react-helmet';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <a
        href="https://www.iubenda.com/privacy-policy/32714190"
        className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
        title="Privacy Policy"
      >
        {' '}
      </a>
      <Helmet>
        <script type="text/javascript">
          {'(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);'}
        </script>
      </Helmet>
    </div>
  );
};

export default PrivacyPolicyPage;
